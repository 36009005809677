@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
*,
*::after,
*::before {
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
}
.container-admin {
  display: flex;
  width: 100%;
}
.search-bar {
  margin: 0.62rem 0 0.50rem 10%;
  background-color: white;
  border-radius: 5px;
  padding: 0.95em;
  /* width: 100vh; */
  transition: all 100ms ease 0s;
}

.admin-menu a {
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: black;
  font-size: 1.5em;
  font-weight: lighter;
}
.admin-menu nav {
  margin-top: 30px;
}
.admin-menu a:hover {
  color: #545cd8;
  font-size: 1.1em;
  font-weight: bold;
}
.admin-menu {
  font-family: Roboto, sans-serif;
  width: 90%; 
  max-width: 250px;
  background-color: white;
  overflow-y: auto; 
  height: 100%; 
}

.menu-title {
  color: #545cd8;
  margin: 0;
  padding: 10px;
  font-size: 0.8em;
  font-weight: bold;
}

.icon {
  margin-right: 5px;
  font-size: 10px;
}

.admin-table {
  font-family: Roboto, san-serif;
  width: 98%;
  margin-left: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 5px;
  -webkit-box-shadow: 9px 9px 12px 5px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 9px 9px 12px 5px rgba(0, 0, 0, 0.17);
  box-shadow: 9px 9px 12px 5px rgba(0, 0, 0, 0.17);
  background-color: white;
}
.admin-table td {
  border-right: 1px solid #e9ecef;
  margin: 2px;
  padding-top: 12px;
  text-align: left;
  color: rgb(51, 51, 51);
  font-size: medium;
}
.admin-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.admin-table tr:nth-child(odd) {
  background-color: #ffffff;
}
.admin-table tr {
  font-size: small;
  text-align: center;
  color: #545cd8;
}
.admin-table tr:hover {
  background: #e0f3ff;
}
.table-btn {
  margin: 30px 0 10px;
  color: #545cd8;
  width: 30%;
  border: none;
  border-radius: 5px;
  padding: 10px;
}
.table-btn:disabled {
  color: #545cd8;
  opacity: 0.5;
}
.table-btn:hover {
  color: white;
  background-color: #545cd8;
}
.admin-table p,
h4 {
  margin: 5px;
  color: rgb(51, 51, 51);
}
.table-input {
  width: 100%;
  padding: 10px;
  margin: 0px;
  border-radius: 5px;
  border: 1px solid grey;
}
.table-pageSet {
  margin-bottom: 0;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
}
.underline {
  border-bottom: 1px solid grey;
  width: 100%;
  opacity: 0.2;
}
.admin-table th {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background: #f2f2f2;
}

.multiline-cell {
  line-height: 1.5;
}
.data-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.chart-container {
  display: block;
  width: 100%;
  height: 300px;
}
.chart2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ck-editor__editable {
  height: 20vh;
}
.pie-chart-legend-marker {
  display: none; /* Hide the legend marker */
}
.pie-chart-legend-box {
  display: none; /* Hide the legend box */
}
.container-table {
  width: 100%;
}
